@import url(//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css);
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: flex;
  width: 100%;
  align-items: stretch; 
  overflow: auto;
}

body {  
  background: #e6dada;  
  overflow: auto;

  margin: 0;
  font-family: 'Segoe UI'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.92rem !important;
  background-color: #f2f7fb !important;
}

.footer-copyright{
  color: aliceblue;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  bottom: 0;
  /* position: absolute; */
  width: 100%;
  background-color: #263544;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 220px;
  max-width: 220px;
  background: #263544;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
  height: 100%;  
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
}

.sidebar-header {
  background: #263544;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #263544;
}

.nav-item:hover {
  color: #263544;
  background: rgba(255, 255, 255, 0.062);
  cursor: default;
}

.items-menu {
  color: #fff;
  background: #263544;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

li a.dropdown-toggle {
  color: #fff;
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  /* overflow-y: scroll; */
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #263544;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #263544;
}

.sidebar-header hr{ display: block; height: 1px;
  border: 0; border-top: 1px solid #ccc;
  margin: 1em 0; padding: 0; }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  /* padding: 20px;
  margin-left: 0;
  height: 100vh; */
}

.react-date-picker__wrapper{
  border: none !important;
}

@media only screen and (max-width: 500px) {
  body {
    /* overflow: hidden; */
  }

  .sidebar.is-open {
    margin-left: 100%;
    min-width:50%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span {
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }


}

@media only screen and (max-width: 1100px) {
  .footer-copyright{
    position: fixed;    
  }
}

.login-block{
    background: #DE6262;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to bottom, #FFB88C, #DE6262); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
float:left;
width:100%;
padding : 50px 0;
overflow-y: hidden;
}
.banner-sec{background:url(/static/media/banner_Login.1f7c6114.jpg) no-repeat left bottom; background-size:cover; min-height:450px; border-radius: 0 10px 10px 0; padding:0; }
.container{background:#fff; border-radius: 10px; box-shadow:15px 20px 0px rgba(0,0,0,0.1); margin-top: 1px;}
.login-sec{padding: 50px 30px; position:relative;}
.login-sec h2{margin-bottom:30px; font-weight:800; font-size:30px; color: #DE6262;}
.login-sec h2:after{content:" "; width:100px; height:5px; background:#FEB58A; display:block; margin-top:20px; border-radius:3px; margin-left:auto;margin-right:auto}
.btn-login{background: #DE6262 !important; color:#fff !important; font-weight:600 !important;}
.btn-cadastro{background: #DE6262 !important; color:#fff !important; font-weight:600 !important;}
.btn-saibamais{background: #FEB58A !important; color:#fff !important; font-weight:600 !important; margin-left: 0px;}
.banner-text{width:70%; position:absolute; bottom:80px; margin-left: 15px;}
.banner-text h2{color:#fff; font-weight:600;}
.banner-text h2:after{content:" "; width:100px; height:5px; background:#FFF; display:block; margin-top:20px; border-radius:3px;}
.banner-text p{color: #fff; font-style: italic; font-size: medium;}

@media only screen and (max-width: 912px){
    .banner-text{width:90%; position:absolute; bottom: 5px; margin-left: 15px;}
}

@media only screen and (max-width: 280px){
    .banner-text{width:90%; position:absolute; bottom: 60px; margin-left: 15px;}
    #tituloPlataforma{display: none;}
}
.cardMouseHover:hover{
    background-color: antiquewhite;
    border-color: burlywood;
}

.blink{
    -webkit-animation: blink 1.5s step-start 0s infinite;
            animation: blink 1.5s step-start 0s infinite;
}

@-webkit-keyframes blink {
    50% {
      opacity: 0.0;
    }
  }

@keyframes blink {
    50% {
      opacity: 0.0;
    }
  }
.corpoReport{
    height: 1100px;
}

@media screen and (min-width:500px) {
    .MobileExcel{
        display: none;
    }
}
